




































































































import { ref } from '@vue/composition-api';

export default {
  props: {
    objectives: {
      type: Array,
      default: () => {}
    },
    addObjective: {
      type: Function,
      default: () => {}
    },
    confirmDeleteObjective: {
      type: Function,
      default: () => {}
    },
    selectObjective: {
      type: Function,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const confirmDelete = ref(false);

    return { confirmDelete };
  }
};
