var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guide-bar"},[_c('v-card',{staticClass:"program-card"},[_c('v-expand-x-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expand),expression:"expand"}],staticClass:"guide-bar__container"},[_c('v-list',{staticClass:"guide-bar__list"},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column"},[(_vm.modelProject != null)?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-lazy',[_c('v-textarea',_vm._g(_vm._b({key:_vm.autoGrowHack,staticClass:"guide-bar__combobox mt-4 mb-4",attrs:{"id":"action","rows":"1","auto-grow":"","hide-details":"","value":_vm.modelProject.action,"label":"Action","prepend-inner-icon":"mdi-tools","outlined":"","rounded":""},on:{"click":function($event){_vm.reloadActions ? _vm.generateActionSynonyms(_vm.modelProject.action) : null},"input":function (val) { return _vm.updateAction(val, _vm.modelProject.id, true); }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-tools")])]},proxy:true}],null,true)},'v-textarea',attrs,false),on))],1)]}}],null,false,2804342099)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.actionOptions.length === 0)?_c('v-list-item',[_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"bounce1"}),_c('div',{staticClass:"bounce2"}),_c('div',{staticClass:"bounce3"})])]):_vm._e(),_vm._l((_vm.actionOptions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)},on:{"click":function($event){return _vm.updateAction(item, _vm.modelProject.id, false)}}})],1)],1)})],2)],1)],1)],1):_vm._e(),(_vm.modelProject != null)?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-lazy',[_c('v-textarea',_vm._g(_vm._b({key:_vm.autoGrowHack,attrs:{"id":"project","rows":"1","auto-grow":"","value":_vm.modelProject.text,"label":"Project","prepend-inner-icon":"mdi-file-chart","outlined":"","rounded":""},on:{"click":function($event){_vm.reloadProjects ? _vm.generateProjectSynonyms(_vm.modelProject.text) : null},"input":function (val) { return _vm.updateProject(val, _vm.modelProject.id, true); }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-file-chart")])]},proxy:true}],null,true)},'v-textarea',attrs,false),on))],1)]}}],null,false,1177517704)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.projectOptions.length === 0)?_c('v-list-item',[_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"bounce1"}),_c('div',{staticClass:"bounce2"}),_c('div',{staticClass:"bounce3"})])]):_vm._e(),_vm._l((_vm.projectOptions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)},on:{"click":function($event){return _vm.updateProject(item, _vm.modelProject.id, false)}}})],1)],1)})],2)],1)],1)],1):_vm._e(),(_vm.modelUser != null)?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-lazy',[_c('v-textarea',_vm._g(_vm._b({key:_vm.autoGrowHack,attrs:{"id":"user","rows":"1","auto-grow":"","value":_vm.modelUser.text,"label":"User","prepend-inner-icon":"mdi-account-group","outlined":"","rounded":""},on:{"click":function($event){_vm.reloadUsers ? _vm.generateUserSynonyms(_vm.modelUser.text) : null},"input":function (val) { return _vm.updateUser(val, _vm.modelUser.id, true); }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-account-group")])]},proxy:true}],null,true)},'v-textarea',attrs,false),on))],1)]}}],null,false,873950248)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.userOptions.length === 0)?_c('v-list-item',[_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"bounce1"}),_c('div',{staticClass:"bounce2"}),_c('div',{staticClass:"bounce3"})])]):_vm._e(),_vm._l((_vm.userOptions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)},on:{"click":function($event){return _vm.updateUser(item, _vm.modelUser.id, false)}}})],1)],1)})],2)],1)],1)],1):_vm._e()]),(_vm.modelObjective != null)?_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-lazy',[_c('v-textarea',_vm._g(_vm._b({key:_vm.autoGrowHack,staticClass:"guide-bar__combobox mb-4",attrs:{"id":"objective","rows":"1","auto-grow":"","hide-details":"","value":_vm.modelObjective.text,"label":"Objective & Key Result","prepend-inner-icon":"mdi-sign-direction","outlined":"","rounded":""},on:{"click":function($event){_vm.reloadObjectives ? _vm.generateObjectiveSynonyms(_vm.modelObjective.text) : null},"input":function (val) { return _vm.updateObjective(val, _vm.modelObjective.id, true); }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-sign-direction")])]},proxy:true}],null,true)},'v-textarea',attrs,false),on))],1)]}}],null,false,1849128448)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[(_vm.objectiveOptions.length === 0)?_c('v-list-item',[_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"bounce1"}),_c('div',{staticClass:"bounce2"}),_c('div',{staticClass:"bounce3"})])]):_vm._e(),_vm._l((_vm.objectiveOptions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)},on:{"click":function($event){return _vm.updateObjective(item, _vm.modelObjective.id, false)}}})],1)],1)})],2)],1)],1)],1):_vm._e(),_c('div',{staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"horizontal-center"},[_c('v-btn',{staticClass:"font-weight-bold mt-3",attrs:{"large":"","rounded":"","outlined":"","depressed":""},on:{"click":function($event){return _vm.$emit('update:generateScopes')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v("Re-generate")],1)],1)])])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }