



























































































































import { computed } from '@vue/composition-api';

export default {
  props: {
    objectives: {
      type: Array,
      default: () => {}
    },
    projects: {
      type: Array,
      default: () => {}
    },
    addProject: {
      type: Function,
      default: () => {}
    },
    confirmDeleteProject: {
      type: Function,
      default: () => {}
    },
    selectProject: {
      type: Function,
      default: () => {}
    },
    curObjectiveId: {
      type: String,
      default: ''
    },
    tmpScopeText: {
      type: String,
      default: ''
    },
    confirmDeleteSavedScope: {
      type: Function,
      default: () => {}
    },
    editScopeText: {
      type: Function,
      default: () => {}
    },
    saveScopeText: {
      type: Function,
      default: () => {}
    },
    insertProgramWithScope: {
      type: Function,
      default: () => {}
    },
    createProgramWithScope: {
      type: Function,
      default: () => {}
    },
    tmpScopeId: {
      type: String,
      default: ''
    },
    displayScopes: {
      type: Array,
      default: () => {}
    },
    expand: {
      type: Boolean,
      default: false
    },
    savedScopes: {
      type: Array,
      default: () => {}
    },
    userType: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const scopeText = computed({
      get: () => props.tmpScopeText || '',
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return { scopeText };
  }
};
