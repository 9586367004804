
























































































































































































import {
  ref,
  reactive,
  toRefs,
  computed,
  defineComponent,
  Ref,
  onBeforeMount
} from '@vue/composition-api';
import { ObjectId } from 'bson';
import * as Realm from 'realm-web';
import { Configuration, OpenAIApi } from 'openai';

import { useUserGetters, useDbGetters, useUserActions, useUserState } from '@/store';
import Objectives from '../../views/Explore/scopes/Objectives.vue';
import Projects from '../../views/Explore/scopes/Projects.vue';
import Results from '../../views/Explore/scopes/Results.vue';
import Users from '../../views/Explore/scopes/Users.vue';
import Wordsmith from '../../views/Explore/scopes/Wordsmith.vue';

export default defineComponent({
  name: 'MBuildScope',

  components: {
    Objectives,
    Projects,
    Results,
    Users,
    Wordsmith
  },

  setup(_props: any, ctx: any) {
    const REALM_APP_ID = process.env.VUE_APP_REALM_ID;
    const realmApp: Realm.App = new Realm.App({ id: REALM_APP_ID! });
    const credentials = Realm.Credentials.anonymous();
    const { user }: any = useUserState(['user']);
    const { getUser, getObjectId } = useUserGetters(['getUser', 'getObjectId']);
    const userType = getUser.value?.providerType;
    const userId = getObjectId.value;

    const { collection } = useDbGetters(['collection']);
    const { getAllPrograms, createScope, fetchScopeState, updateScope } = useUserActions([
      'getAllPrograms',
      'createScope',
      'fetchScopeState',
      'updateScope'
    ]);
    const objective = ref();
    const project = ref();
    const userComp = ref();
    const employerData: Ref<any[]> = ref([]);
    const state = reactive({
      programFilter: 'All' as 'All' | 'Bookmarked',
      ageFilter: null as any,
      residenceFilter: null,
      pathwaysFilter: [{} as any],
      bookmarked: [] as ObjectId[],
      residenceOptions: [] as string[]
    });
    const curObjectiveId = ref(null);
    const curProjectId = ref(null);
    const curUserId = ref(null);
    const stepId = ref(1);
    const tmpObjective = reactive({
      id: null,
      text: null,
      priority: null
    });
    const tmpProject = reactive({
      id: null,
      objectiveId: null,
      text: null,
      priority: null,
      difficulty: null,
      action: null
    });
    const tmpUser = reactive({
      id: null,
      objectiveId: null,
      projectId: null,
      text: null,
      priority: null
    });
    const objectives = ref([]);
    const projects = ref([]);
    const users = ref([]);
    const tmp = ref([]);
    const priorityOrder = reactive({ Low: 0, Medium: 1, High: 2 });
    const difficultyOrder = ref({ Easy: 0, Medium: 1, Hard: 2 });
    const newAction = ref(null);
    const actionItems = ref([
      'Create',
      'Make',
      'Produce',
      'Develop',
      'Code',
      'Plan',
      'Research',
      'Build',
      'Prototype'
    ]);
    const confirmDelete = ref(false);
    const deleteFunc: any = ref(null);
    const deleteArg = ref(null);
    const tmpScope = reactive({
      id: null,
      objectiveId: null,
      projectId: null,
      userId: null,
      text: null
    });
    const results = reactive({
      processedObjectives: [],
      processedProjects: [],
      processedUsers: [],
      scopes: []
    });
    const objectiveOption = ref(null);
    const actionOption = ref(null);
    const projectOption = ref(null);
    const userOption = ref(null);
    const numScopes = ref(0);
    const displayScopes = ref([]);
    const actionSynonyms = ref(null);
    const projectSynonyms = ref(null);
    const userSynonyms = ref(null);
    const objectiveSynonyms = ref(null);

    // screen 5
    const tmpScopeText = ref('');
    const tmpScopeId = ref(null);
    const savedScopes = ref([]);
    const expand = ref(true);
    const isSyncing = ref(false);
    const currentScope = ref();

    // others
    const autoGrowHack = ref(false);

    const loadingObject = computed(() => {
      return {
        loading: true,
        'loading-rounded': true,
        'loading-animated': true
      };
    });

    const showCloseBtn = computed(() => {
      return !window.location.pathname.includes('/scope');
    });

    const savedBookmarks = localStorage.getItem('bookmarkedPrograms'); // * grab bookmarks from localStorage
    if (savedBookmarks) state.bookmarked = JSON.parse(savedBookmarks).map(id => new ObjectId(id));

    getAllPrograms().then(programs => {
      programs.sort((a, b) => (a.programName || '').localeCompare(b.programName || ''));
      employerData.value = programs;
      programs.forEach(program => {
        if (program.requiredResidency) state.residenceOptions.push(...program.requiredResidency);
      });
    });

    async function syncDb() {
      if (userType !== 'anon-user') {
        isSyncing.value = true;
        let scopeState;
        if (currentScope.value) {
          const data = {
            user_id: user.value?._id?.toString(),
            objectives: JSON.stringify(objectives.value),
            projects: JSON.stringify(projects.value),
            users: JSON.stringify(users.value),
            actions: JSON.stringify(actionItems.value),
            savedScopes: JSON.stringify(savedScopes.value)
          };
          scopeState = await updateScope(data);
          currentScope.value = scopeState;
        } else {
          scopeState = await createScope(userId?.toString());
          const data = {
            user_id: user.value?._id?.toString(),
            objectives: JSON.stringify(objectives.value),
            projects: JSON.stringify(projects.value),
            users: JSON.stringify(users.value),
            actions: JSON.stringify(actionItems.value),
            savedScopes: JSON.stringify(savedScopes.value)
          };
          scopeState = await updateScope(data);
          currentScope.value = scopeState;
        }
        isSyncing.value = false;
      } else {
        // save anon user data into local storage
        localStorage.setItem('objectives', JSON.stringify(objectives.value));
        localStorage.setItem('projects', JSON.stringify(projects.value));
        localStorage.setItem('users', JSON.stringify(users.value));
        localStorage.setItem('actions', JSON.stringify(actionItems.value));
        localStorage.setItem('savedScopes', JSON.stringify(savedScopes.value));
      }
    }

    if (ctx.root.$route.query) {
      let query = ctx.root.$route.query.pathway;
      if (!Array.isArray(query)) query = [query];
      query = query.filter(word => !!word);
      if (!query.length)
        state.pathwaysFilter = [
          {
            text: 'All',
            color: 'grey darken-4'
          }
        ];
      else
        state.pathwaysFilter = query.map(pathway => ({
          text: pathway as string,
          color: 'grey darken-4'
        }));
    }

    // const currentUnit = ref(ListView);

    const filteredPrograms = computed(() => {
      let visiblePrograms;
      if (state.programFilter === 'Bookmarked') {
        visiblePrograms = employerData.value.filter((program: Record<string, any>) =>
          state.bookmarked.some((id: ObjectId) => id.equals(program._id))
        );
      } else {
        visiblePrograms = employerData.value;
      }

      if (state.residenceFilter && state.residenceFilter !== 'None') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.requiredResidency && program.requiredResidency.includes(state.residenceFilter)
        );
      }

      if (state.ageFilter && state.ageFilter !== 'All') {
        visiblePrograms = visiblePrograms.filter(
          program =>
            program.ageRange[0] <= parseInt(state.ageFilter, 10) &&
            program.ageRange[1] >= parseInt(state.ageFilter, 10)
        );
      }

      if (state.pathwaysFilter.length && !state.pathwaysFilter.some(obj => obj.text === 'All')) {
        const filters = state.pathwaysFilter.map(obj => obj.text);
        visiblePrograms = visiblePrograms.filter(
          program => program.pathways && program.pathways.some(pathway => filters.includes(pathway))
        );
      }

      return visiblePrograms;
    });

    const bookmarkProgram = (programId: ObjectId) => {
      if (state.bookmarked.some((id: ObjectId) => id.equals(programId)))
        state.bookmarked = state.bookmarked.filter((id: ObjectId) => !id.equals(programId));
      else state.bookmarked.push(programId);
      localStorage.setItem('bookmarkedPrograms', JSON.stringify(state.bookmarked)); //* save bookmarks to localStorage
    };

    const configuration = new Configuration({
      apiKey: process.env.VUE_APP_OPENAI_API_KEY
    });
    const openai = new OpenAIApi(configuration);

    function setTmpScopeTxt(val) {
      tmpScopeText.value = val;
    }

    function addObjective() {
      const newObjective: any = tmpObjective;
      newObjective.id = `id${new Date().getTime()}`; // this.lastObjectiveId == null ? 0 : this.lastObjectiveId + 1;
      // this.lastObjectiveId = newObjective.id;
      objectives.value.push({ ...newObjective });
    }

    function addProject(objectiveId) {
      const newProject: any = { ...tmpProject };
      newProject.id = `id${new Date().getTime()}`; // this.lastProjectId == null ? 0 : this.lastProjectId + 1;
      newProject.objectiveId = objectiveId;
      projects.value.push({ ...newProject });
    }

    async function selectObjective(objectiveId) {
      objectives.value = objectives.value.filter(item => item.text != null && item.text.length > 0);
      curObjectiveId.value = objectiveId;

      const childProjects = projects.value.filter(item => item.objectiveId === objectiveId);
      if (childProjects.length === 0) addProject(objectiveId);

      await syncDb();
      stepId.value = 2;
    }

    async function deleteObjective(objectiveId) {
      objectives.value = objectives.value.filter(item => item.id !== objectiveId);
      projects.value = projects.value.filter(item => item.objectiveId !== objectiveId);
      users.value = users.value.filter(item => item.objectiveId !== objectiveId);
      results.scopes = results.scopes.filter(item => item.objectiveId !== objectiveId);

      await syncDb();
      objective.value.confirmDelete = false;
    }

    function sortPriorityObjective() {
      objectives.value.sort((a, b) => {
        return (
          (a.priority == null && b.priority != null) ||
          priorityOrder[a.priority] < priorityOrder[b.priority]
        );
      });
    }

    function addUser(objectiveId, projectId) {
      const newUser = { ...tmpUser };
      newUser.id = `id${new Date().getTime()}`;
      newUser.objectiveId = objectiveId;
      newUser.projectId = projectId;
      users.value.push({ ...newUser });
    }

    async function selectProject(projectId) {
      projects.value = projects.value.filter(item => item.text != null && item.text.length > 0);
      curProjectId.value = projectId;

      const childUsers = users.value.filter(item => item.projectId === projectId);
      if (childUsers.length === 0)
        addUser(projects.value?.find(item => item.id === projectId)?.objectiveId, projectId);

      await syncDb();
      stepId.value = 3;
    }

    async function deleteProject(projectId) {
      projects.value = projects.value.filter(item => item.id !== projectId);
      users.value = users.value.filter(item => item.projectId !== projectId);
      results.scopes = results.scopes.filter(item => item.projectId !== projectId);

      await syncDb();
      project.value.confirmDelete = false;
    }

    function sortPriorityProject() {
      projects.value.sort((a, b) => {
        return (
          (a.priority == null && b.priority != null) ||
          priorityOrder[a.priority] < priorityOrder[b.priority]
        );
      });
    }

    function sortDifficultyProject() {
      projects.value.sort((a, b) => {
        return (
          (a.difficulty == null && b.difficulty != null) ||
          difficultyOrder[a.difficulty] < difficultyOrder[b.difficulty]
        );
      });
    }

    async function updateAction(newAction) {
      actionItems.value.push(newAction);

      await syncDb();
    }

    async function removeAction(item) {
      actionItems.value = actionItems.value.filter(action => action !== item);

      await syncDb();
    }

    async function deleteUser(userId) {
      users.value = users.value.filter(item => item.id !== userId);
      results.scopes = results.scopes.filter(item => item.userId !== userId);

      await syncDb();
      userComp.value.confirmDelete = false;
    }

    function sortPriorityUser() {
      users.value.sort((a, b) => {
        return (
          (a.priority == null && b.priority != null) ||
          priorityOrder[a.priority] < priorityOrder[b.priority]
        );
      });
    }

    function confirmDeleteFunc() {
      if (deleteFunc.value != null && deleteArg.value != null) deleteFunc(this.deleteArg);
      confirmDelete.value = false;
    }

    async function getScopeState() {
      try {
        const scopeState = await fetchScopeState(user.value?._id);
        if (scopeState._id) currentScope.value = scopeState;
        let storedObjectives = JSON.parse(localStorage.getItem('objectives'));
        let storedProjects = JSON.parse(localStorage.getItem('projects'));
        let storedUsers = JSON.parse(localStorage.getItem('users'));
        let storedActions = JSON.parse(localStorage.getItem('actions'));
        let storedSavedScopes = JSON.parse(localStorage.getItem('savedScopes'));

        if (
          storedObjectives != null &&
          storedProjects != null &&
          storedUsers != null &&
          storedActions != null &&
          storedSavedScopes != null
        ) {
          // retrieve items from LocalStorage for new user
          objectives.value = storedObjectives;
          sortPriorityObjective();
          projects.value = storedProjects;
          sortPriorityProject();
          // projects.value.forEach(project => {
          //   this.$set(project, 'actionTmp', null);
          // });
          actionItems.value = storedActions;
          users.value = storedUsers;
          sortPriorityUser();
          savedScopes.value = storedSavedScopes;
          if (objectives.value.length === 0) addObjective();
          if (savedScopes.value.length > 0) stepId.value = 5;

          // remove all items from Local Storage
          localStorage.removeItem('objectives');
          localStorage.removeItem('projects');
          localStorage.removeItem('users');
          localStorage.removeItem('actions');
          localStorage.removeItem('savedScopes');
          localStorage.removeItem('curSavedScope');
        } else if (scopeState != null) {
          storedObjectives = JSON.parse(scopeState.objectives);
          storedProjects = JSON.parse(scopeState.projects);
          storedActions = JSON.parse(scopeState.actions);
          storedUsers = JSON.parse(scopeState.users);
          storedSavedScopes = JSON.parse(scopeState.savedScopes);
          if (storedObjectives != null) {
            objectives.value = storedObjectives;
            sortPriorityObjective();
          }
          if (storedProjects != null) {
            projects.value = storedProjects;
            sortPriorityProject();
          }
          // projects.value.forEach(project => {
          //   this.$set(project, 'actionTmp', null);
          // });

          if (storedActions != null) actionItems.value = storedActions;

          if (storedUsers != null) {
            users.value = storedUsers;
            sortPriorityUser();
          }
          if (storedSavedScopes != null) savedScopes.value = storedSavedScopes;

          if (objectives.value.length === 0) addObjective();

          if (savedScopes.value.length > 0) stepId.value = 5;
        }
      } catch (error) {
        console.log(error);
      }
    }

    // screen 4
    async function generateScopes() {
      stepId.value = 4;

      numScopes.value = 0;
      displayScopes.value = [];

      const objectiveText = objectives.value?.find(item => item.id === curObjectiveId.value)?.text;
      const actionText = projects.value?.find(item => item.id === curProjectId.value)?.action;
      const projectText = projects.value?.find(item => item.id === curProjectId.value)?.text;
      const userText = users.value?.find(item => item.id === curUserId.value)?.text;

      // generate general scopes
      const dummyScope = [
        'We are requesting you to create ',
        actionText,
        ' a ',
        projectText,
        ' for ',
        userText,
        ' to ',
        objectiveText
      ].join('');

      const generatedScopes = [];

      // 2nd sentence: grammar(raw)
      let response = await openai.createCompletion('text-davinci-001', {
        prompt: [
          'Correct this to grammatically correct English:\n\n',
          dummyScope.replace('We are requesting you to create ', ''),
          '.'
        ].join(''),
        temperature: 0,
        max_tokens: 150,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0
      });

      const result = response.data?.choices[0];
      // console.log(response);
      generatedScopes.push(result.text.replace(/(^:+|^\s+)/gm, ''));

      // 3rd-14th: summarize
      response = await openai.createCompletion('text-davinci-001', {
        prompt: [dummyScope, 'Summarize this for a second-grade student:\n\n'].join(''),
        temperature: 0.9, // 0.7,
        max_tokens: 250, // 64,
        top_p: 1.0, // 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
        n: 14 // CHANGE number of generated scopes here
      });

      let results = response?.data?.choices;

      // remove duplicates
      results = results?.filter((value, index, self) => self.indexOf(value) === index);

      results?.forEach(item => {
        generatedScopes.push(item.text.replace(/(^:+|^\s+)/gm, ''));
      });

      displayScopes.value = generatedScopes;
      numScopes.value = generatedScopes.length;
    }

    async function selectUser(userId) {
      users.value = users.value.filter(item => item.text != null && item.text.length > 0);
      curUserId.value = userId;

      await syncDb();

      autoGrowHack.value = !autoGrowHack.value;
      await generateScopes();
    }

    function addScope(objectiveId, projectId, userId, scopeText) {
      const newScope: any = tmpScope;
      newScope.id = results.scopes.length;
      newScope.objectiveId = objectiveId;
      newScope.projectId = projectId;
      newScope.userId = userId;
      newScope.text = scopeText;
      results.scopes.push({ ...newScope });
    }

    function updateObjectiveGuideBar({ text, objectiveId }) {
      objectives.value.find(item => item.id === objectiveId).text = text;
    }

    function updateActionGuideBar({ action, projectId }) {
      projects.value.find(item => item.id === projectId).action = action;
    }

    function updateProjectGuideBar({ text, projectId }) {
      projects.value.find(item => item.id === projectId).text = text;
    }

    function updateUserGuideBar({ text, userId }) {
      users.value.find(item => item.id === userId).text = text;
    }

    function selectScope(text) {
      if (text != null) {
        stepId.value = 5;
        tmpScopeText.value = text;
        tmpScopeId.value = null;
      } else {
        const objectiveText = objectives.value?.find(
          item => item.id === curObjectiveId.value
        )?.text;
        const actionText = projects.value?.find(item => item.id === curProjectId.value)?.action;
        const projectText = projects.value?.find(item => item.id === curProjectId.value)?.text;
        const userText = users.value?.find(item => item.id === curUserId.value)?.text;

        stepId.value = 5;
        tmpScopeText.value = [actionText, ' ', projectText, ' ', userText, ' ', objectiveText].join(
          ''
        );
        tmpScopeId.value = null;
      }
    }

    // screen 5
    async function saveScopeText(id, text) {
      if (id == null) {
        const newScope = { ...tmpScope };
        newScope.id = `id${new Date().getTime()}`; // this.savedScopes.length;
        newScope.text = text;
        savedScopes.value?.push({ ...newScope });
      } else {
        savedScopes.value.find(item => item.id === id).text = text;
      }
      await syncDb();
    }

    function editScopeText(id, text) {
      tmpScopeText.value = text;
      tmpScopeId.value = id;
    }

    async function deleteSavedScope(id) {
      savedScopes.value = savedScopes.value?.filter(item => item.id !== id);

      await syncDb();
    }

    function createProgramWithScope(scopeText) {
      localStorage.setItem('curSavedScope', JSON.stringify(scopeText));
      ctx.root.$router.push({
        name: 'signup',
        query: { redirect: 'guide' }
      });
    }

    async function insertProgramWithScope(scopeText) {
      if (window.location.pathname.includes('/scope')) {
        localStorage.setItem('curSavedScope', JSON.stringify(scopeText));
        // await this.collectionProgram
        //   .insertOne({
        //     organizers: [this.userId],
        //     participants: [],
        //     dateCreated: new Date(),
        //     licensed: false
        //   })
        //   .then(result => {
        //     ctx.root.$router.push({
        //       name: 'guide',
        //       params: { programId: result.insertedId, page: '0' }
        //     });
        //   });
      } else ctx.emit('update:useScope', scopeText);
    }

    onBeforeMount(() => {
      getScopeState();
    });

    return {
      ...toRefs(state),
      deleteObjective,
      savedScopes,
      deleteUser,
      userComp,
      objective,
      project,
      deleteProject,
      stepId,
      curObjectiveId,
      updateProjectGuideBar,
      selectScope,
      insertProgramWithScope,
      editScopeText,
      confirmDeleteFunc,
      generateScopes,
      updateUserGuideBar,
      saveScopeText,
      selectProject,
      getScopeState,
      addProject,
      setTmpScopeTxt,
      createProgramWithScope,
      updateActionGuideBar,
      deleteSavedScope,
      loadingObject,
      updateObjectiveGuideBar,
      isSyncing,
      curProjectId,
      removeAction,
      selectUser,
      curUserId,
      updateAction,
      addObjective,
      filteredPrograms,
      selectObjective,
      showCloseBtn,
      syncDb,
      bookmarkProgram,
      addScope,
      results,
      snackbar: true,
      priorityItems: ['High', 'Medium', 'Low'],
      difficultyItems: ['Hard', 'Medium', 'Easy'],
      collection,
      realmApp,
      credentials,
      openai,
      userType,
      userId,
      tmp,
      tmpObjective,
      numScopes,
      users,
      projects,
      objectives,
      tmpProject,
      tmpUser,
      priorityOrder,
      difficultyOrder,
      newAction,
      confirmDelete,
      deleteFunc,
      deleteArg,
      tmpScope,
      objectiveOption,
      actionOption,
      projectOption,
      userOption,
      displayScopes,
      actionSynonyms,
      projectSynonyms,
      userSynonyms,
      objectiveSynonyms,
      tmpScopeText,
      tmpScopeId,
      expand,
      autoGrowHack,
      actionItems,
      addUser,
      currentScope
    };
  }
});
