















































































































































import { ref } from '@vue/composition-api';

export default {
  props: {
    users: {
      type: Array,
      default: () => {}
    },
    projects: {
      type: Array,
      default: () => {}
    },
    objectives: {
      type: Array,
      default: () => {}
    },
    addUser: {
      type: Function,
      default: () => {}
    },
    confirmDeleteUser: {
      type: Function,
      default: () => {}
    },
    selectUser: {
      type: Function,
      default: () => {}
    },
    curObjectiveId: {
      type: String,
      default: ''
    },
    curProjectId: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const confirmDelete = ref(false);

    return { confirmDelete };
  }
};
