















































































































































































































































import { ref, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'Bar',
  props: {
    objective: {
      required: true
    },
    project: {
      required: true
    },
    user: {
      required: true
    },
    openai: {
      required: true
    },
    autoGrowHack: {
      required: true
    }
  },

  setup(props, ctx) {
    const expand = ref(true); // open or closed sidebar

    return {
      expand
    };
  },
  data: () => ({
    search: true,
    key: 'id',
    modelObjective: null,
    modelAction: null,
    modelProject: null,
    modelUser: null,
    actionOptions: [],
    projectOptions: [],
    userOptions: [],
    objectiveOptions: [],
    reloadActions: true,
    reloadProjects: true,
    reloadUsers: true,
    reloadObjectives: true
  }),

  watch: {
    objective(newVal) {
      this.modelObjective = JSON.parse(JSON.stringify(newVal));
      this.reloadObjectives = true;
    },
    project(newVal) {
      // console.log(newVal);
      this.modelProject = JSON.parse(JSON.stringify(newVal));
      this.reloadActions = true;
      this.reloadProjects = true;
    },
    user(newVal) {
      this.modelUser = JSON.parse(JSON.stringify(newVal));
      this.reloadUsers = true;
    }
  },

  methods: {
    updateAction(action, projectId, reload) {
      this.modelProject.action = action;
      this.reloadActions = reload;
      this.$emit('update:actionGuideBar', {
        action,
        projectId
      });
    },
    updateProject(project, projectId, reload) {
      this.modelProject.text = project;
      this.reloadProjects = reload;
      this.$emit('update:projectGuideBar', { text: project, projectId });
    },
    updateUser(user, userId, reload) {
      this.modelUser.text = user;
      this.reloadUsers = reload;
      this.$emit('update:userGuideBar', { text: user, userId });
    },
    updateObjective(objective, objectiveId, reload) {
      this.modelObjective.text = objective;
      this.reloadObjectives = reload;
      this.$emit('update:objectiveGuideBar', { text: objective, objectiveId });
    },
    async generateActionSynonyms(action) {
      this.reloadActions = false;
      const response = await this.openai.createCompletion('text-davinci-001', {
        prompt: ['Find 5 synonyms for each of these words:\n\n', action, '.'].join(''),
        temperature: 0,
        max_tokens: 32,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0
      });

      const [result] = response.data.choices;

      if (
        RegExp('(?=.*1\\.)(?=.*2\\.)(?=.*3\\.)').test(result.text.replace(/(\r\n|\r|\n|\s)+/gm, ''))
      ) {
        this.actionOptions = result.text
          .replace(/(^(\r\n|\r|\n)+)/gm, '')
          .split('\n')
          .map(item => {
            return item.substring(3).trim();
          });
      } else {
        this.actionOptions = result.text
          .replace(/(^(\r\n|\r|\n)+)/gm, '')
          .split(',')
          .map(item => {
            return item.trim();
          });
      }

      // console.log(result.text.replace(/(^(\r\n|\r|\n)+)/gm, ''));
      // console.log(this.actionOptions);
      // console.log(result.text.replace(/(\r\n|\r|\n|\s)+/gm, ''));
    },
    async generateProjectSynonyms(project) {
      this.reloadProjects = false;
      const response = await this.openai.createCompletion('text-davinci-001', {
        prompt: ['Find 5 other meanings for this sentence:\n\n', project, '.'].join(''),
        temperature: 0,
        max_tokens: 200,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0
      });

      const [result] = response.data.choices;

      if (
        RegExp('(?=.*1\\.)(?=.*2\\.)(?=.*3\\.)').test(result.text.replace(/(\r\n|\r|\n|\s)+/gm, ''))
      ) {
        this.projectOptions = result.text
          .replace(/(^(\r\n|\r|\n)+)/gm, '')
          .split('\n')
          .map(item => {
            return item.substring(3).trim();
          });
      } else {
        this.projectOptions = result.text
          .replace(/(^(\r\n|\r|\n)+)/gm, '')
          .split(',')
          .map(item => {
            return item.trim();
          });
      }
      // console.log(result.text.replace(/(^(\r\n|\r|\n)+)/gm, ''));
      // console.log(result.text.replace(/(\r\n|\r|\n|\s)+/gm, ''));
    },
    async generateUserSynonyms(user) {
      this.reloadUsers = false;
      const response = await this.openai.createCompletion('text-davinci-001', {
        prompt: ['Find 5 synonyms for this phrase:\n\n', user, '.'].join(''),
        temperature: 1.0,
        max_tokens: 64,
        top_p: 0.0,
        frequency_penalty: 0.5,
        presence_penalty: 0.0
      });

      const [result] = response.data.choices;

      if (
        RegExp('(?=.*1\\.)(?=.*2\\.)(?=.*3\\.)').test(result.text.replace(/(\r\n|\r|\n|\s)+/gm, ''))
      ) {
        this.userOptions = result.text
          .replace(/(^(\r\n|\r|\n)+)/gm, '')
          .split('\n')
          .map(item => {
            return item.substring(3).trim();
          });
      } else {
        this.userOptions = result.text
          .replace(/(^(\r\n|\r|\n)+)/gm, '')
          .split(',')
          .map(item => {
            return item.trim();
          });
      }
      // console.log(result.text.replace(/(^(\r\n|\r|\n)+)/gm, ''));
      // console.log(this.actionOptions);
      // console.log(result.text);
      // console.log(result.text.replace(/(\r\n|\r|\n|\s)+/gm, ''));
    },
    async generateObjectiveSynonyms(objective) {
      // console.log('reload');
      this.reloadObjectives = false;
      const response = await this.openai.createCompletion('text-davinci-001', {
        prompt: ['Find 5 other meanings for this sentence:\n\n', objective, '.'].join(''),
        temperature: 0,
        max_tokens: 200,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0
      });

      const [result] = response.data.choices;

      if (
        RegExp('(?=.*1\\.)(?=.*2\\.)(?=.*3\\.)').test(result.text.replace(/(\r\n|\r|\n|\s)+/gm, ''))
      ) {
        this.objectiveOptions = result.text
          .replace(/(^(\r\n|\r|\n)+)/gm, '')
          .split('\n')
          .map(item => {
            return item.substring(3).trim();
          });
      } else {
        this.objectiveOptions = result.text
          .replace(/(^(\r\n|\r|\n)+)/gm, '')
          .split(',')
          .map(item => {
            return item.trim();
          });
      }
      // console.log(result.text.replace(/(^(\r\n|\r|\n)+)/gm, ''));
      // console.log(this.actionOptions);
    }
  }
});
