











































































import { defineComponent } from '@vue/composition-api';
import ScopeBar from '../ScopeBar.vue';

export default {
  components: {
    'guide-bar': ScopeBar
  },
  props: {
    users: {
      type: Array,
      default: () => {}
    },
    objectives: {
      type: Array,
      default: () => {}
    },
    projects: {
      type: Array,
      default: () => {}
    },
    addUser: {
      type: Function,
      default: () => {}
    },
    confirmDeleteUser: {
      type: Function,
      default: () => {}
    },
    selectScope: {
      type: Function,
      default: () => {}
    },
    curObjectiveId: {
      type: String,
      default: ''
    },
    curUserId: {
      type: String,
      default: ''
    },
    curProjectId: {
      type: String,
      default: ''
    },
    openai: {
      required: false,
      default: ''
    },
    autoGrowHack: {
      type: Boolean,
      default: false
    },
    numScopes: {
      type: Number,
      default: 0
    },
    displayScopes: {
      type: Array,
      default: () => {}
    },
    loadingObject: {
      type: Object,
      default: () => {}
    },
    actionItems: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  setup() {}
};
