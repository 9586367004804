














































































































































































import { ref } from '@vue/composition-api';

export default {
  props: {
    objectives: {
      type: Array,
      default: () => {}
    },
    projects: {
      type: Array,
      default: () => {}
    },
    addProject: {
      type: Function,
      default: () => {}
    },
    confirmDeleteProject: {
      type: Function,
      default: () => {}
    },
    selectProject: {
      type: Function,
      default: () => {}
    },
    curObjectiveId: {
      type: String,
      default: ''
    },
    updateAction: {
      type: Function,
      default: () => {}
    },
    actionItems: {
      type: Array,
      default: () => []
    },
    removeAction: {
      type: Function,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const confirmDelete = ref(false);

    return { confirmDelete };
  }
};
